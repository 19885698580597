const navLinks = [
    {
        path: "/",
        title: "Home"
    },
    {
        path: "/services",
        title: "Services"
    },
    {
        path: "/products",
        title: "Products"
    },
    {
        path: "/contacts",
        title: "Contacts"
    }
]

export default navLinks;